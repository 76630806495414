import { request } from "./index";

export const getSchools = (data) =>
  request("get", "/schools", {
    params: data,
  });

export const getSchool = (data) => request("get", `/schools/${data}`);

export const createSchool = (data) => request("post", `/schools`, data);

export const updateSchool = (data) =>
  request("put", `/schools/${data.id}`, data.body);

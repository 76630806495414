<template>
  <v-container>
    {{ school }}
  </v-container>
</template>

<script>
import { getSchool } from "@/services/league/schools.js";

export default {
  data() {
    return {
      loading: true,
      school: null,
    };
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        await getSchool(this.$route.params.id).then((res) => {
          this.loading = false;
        });
      } catch (err) {
        console.log(err);
        this.$router.push({ path: "/league/schools" });
      }
    },
  },
};
</script>

<style></style>
